export const coachHeaderStyle = {
  input: {
    width: '320px',
    background: 'white',
    height: '44px',
    borderRadius: '8px',
    padding: '10px 14px',
    color: '#101828',
    '& .MuiSelect-icon': {
      '& path': {
        fill: '#858585',
      },
    },
  },
};
