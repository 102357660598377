import React, { useEffect, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Logo from '../../../public/logo.svg';

import MuiList from '@mui/material/List';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import MuiSvgIcon from '@mui/material/SvgIcon';
import { MAIN_MENU, USER_MENU } from './menu-list';

import Link from 'next/link';
import MuiListItemText from '@mui/material/ListItemText';
import { Collapse, Stack, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { manualSetTeam } from '../../../redux/current-team';
import router from 'next/router';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import IconButton from '@mui/material/IconButton';
import { selectUser, selectUserTeams } from '../../../redux/user';
import {
  fetchSharedOwners,
  selectSharedOwners,
} from '../../../redux/shared-access';
import { useTranslations } from 'next-intl';

interface LinkProps {
  title: string;
  url?: string;
  icon?: any;
  customIcon: boolean;
  subMenu?: any;
  callback?: Function;
}

interface MenuProps {
  item: LinkProps;
  disabled?: boolean;
}

interface MenuItemProps {
  item: LinkProps;
  openNav?: boolean;
  callback?: Function;
  disabled?: boolean;
}

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: `200px !important`,
    background: '#000',
    border: 0,
    justifyContent: 'space-between',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    overflowX: 'hidden',
    ...(!open && {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '82px !important',
      '& .MuiListItemText-root': {
        visibility: 'hidden',
      },
      '& .MuiButtonBase-root > .MuiSvgIcon-root': {
        visibility: 'hidden',
      },
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const ListItemButton = styled(MuiListItemButton)({
  padding: 12,
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#344054',
  },
});

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  color: theme.palette.common.white,
  '& span': {
    display: 'block',
    width: '100px',
    whiteSpace: 'normal',
    wordBreak: ' break-word',
  },
}));

const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: '40px',
});

const List = styled(MuiList)<{ component?: React.ElementType }>({
  margin: '1rem 1rem 0 1rem',
});

const SvgIcon = styled(MuiSvgIcon)<{ component?: React.ElementType }>({
  width: 'auto',
  height: 'auto',
  '& path': {
    fill: 'transparent',
  },
  marginLeft: '4px',
});

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const Sidebar = () => {
  const dispatch = useDispatch();
  const [openNav, setOpenNav] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [avatar, setAvatar] = useState<string>(
    'https://www.pngkit.com/png/full/302-3022217_roger-berry-avatar-placeholder.png'
  );
  const [mainMenu, setMainMenu] = useState(MAIN_MENU);
  const [hasSharedAccess, setHasSharedAccess] = useState(false);
  const teamList = useSelector(selectUserTeams);
  const sharedAccess = useSelector(selectSharedOwners);
  const user = useSelector(selectUser);

  useEffect(() => {
    setHasSharedAccess(sharedAccess.length > 0);
  }, [sharedAccess]);
  useEffect(() => {
    if (user?.id) {
      dispatch(fetchSharedOwners());
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (teamList?.length > 0) {
      const __mainMenu = [...MAIN_MENU];
      const teamMenuArray: { title: string; callback: () => void }[] = [];
      teamList.map((team) => {
        teamMenuArray.push({
          title: team.name,
          callback: () => selectTeam(team),
        });
      });
      __mainMenu.map((item) => {
        if (item.title === 'teams') {
          item['subMenu'] = teamMenuArray;
        }
        return item;
      });
      setMainMenu(__mainMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamList]);

  const selectTeam = (team: any) => {
    const name = team.name.toLowerCase();

    dispatch(manualSetTeam(team));
    router.push(`/team-dashboard/${name.replaceAll(' ', '-')}`);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  const handleOpenNav = () => {
    setOpenNav(true);
  };

  return (
    <>
      <Drawer variant="permanent" open={openNav} sx={{ display: 'flex' }}>
        <Box>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              height: 82,
              '& svg': {
                minWidth: '82px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Logo />
              {openNav && (
                <IconButton
                  onClick={toggleNav}
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                >
                  <ArrowBackIosRoundedIcon
                    sx={{ minWidth: '30px !important' }}
                  />
                </IconButton>
              )}
            </Box>
          </Box>

          <List component="nav">
            {mainMenu.map((item: any, index: any) => (
              <MenuItem
                key={`main-menu-${index}`}
                item={item}
                callback={handleOpenNav}
                openNav={openNav}
                disabled={item.title === 'shared-swimmer' && !hasSharedAccess}
              />
            ))}
          </List>
        </Box>
        <Box>
          <List component="nav">
            {USER_MENU.map((item, index) => (
              <MenuItem
                key={`user-menu-${index}`}
                item={item}
                callback={handleOpenNav}
                openNav={openNav}
              />
            ))}
          </List>
          <Stack
            direction="row"
            justifyContent="flex-start"
            sx={{ marginLeft: '1rem' }}
          >
            <button
              aria-describedby={id}
              style={{ background: 'transparent', margin: '24px 0' }}
            >
              <Avatar
                src={avatar}
                sx={{
                  width: 48,
                  height: 48,
                  backgroundColor: '#F2F4F7',
                  color: '#475467',
                  fontSize: 18,
                  fontWeight: 500,
                }}
              />
            </button>
          </Stack>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            color="white"
            justifyContent="center"
            textAlign="center"
          >
            {process.env.npm_package_version}
          </Typography>
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <UserMenu />
          </Popover> */}
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;

const hasChildren = (item: any) => {
  const { subMenu: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
};

const MenuItem = ({ item, callback, openNav, disabled }: MenuItemProps) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={item}
      disabled={disabled}
      {...(hasChildren(item) && { callback: callback, openNav: openNav })}
    />
  );
};

const ListItemLink = ({ item, disabled }: MenuProps) => {
  const t = useTranslations('SideMenu');
  const isMenu = MAIN_MENU.some((menu: any) => menu.title === item.title);
  return (
    <Link href={item.url} passHref>
      <ListItemButton disabled={disabled}>
        <ListItemIcon>
          {item?.customIcon ? (
            <SvgIcon component={item.icon} inheritViewBox />
          ) : (
            <MuiSvgIcon
              component={item?.icon}
              inheritViewBox
              sx={{ color: '#FFF', ml: '4px' }}
            />
          )}
        </ListItemIcon>

        <ListItemText
          primary={!isMenu ? item.title : `${t(item.title)}`}
          sx={{ color: '#FFF' }}
        />
      </ListItemButton>
    </Link>
  );
};

const ListItemClick = ({ item }: MenuProps) => {
  const { callback } = item;

  const handleCallBack = () => {
    if (callback !== undefined) {
      callback();
    }
  };

  const t = useTranslations('SideMenu');

  const isSetting = USER_MENU[0].subMenu.some(
    (menu: any) => menu.title === item.title
  );

  return (
    <ListItemButton onClick={handleCallBack}>
      <ListItemIcon>
        {item?.customIcon ? (
          <SvgIcon component={item.icon} inheritViewBox />
        ) : (
          <MuiSvgIcon
            component={item.icon}
            inheritViewBox
            sx={{ color: '#FFF', ml: '4px' }}
          />
        )}
      </ListItemIcon>

      <ListItemText
        primary={!isSetting ? item.title : `${t(item.title)}`}
        sx={{ color: '#FFF' }}
      />
    </ListItemButton>
  );
};

const SingleLevel = ({ item, disabled }: MenuProps) => {
  const { url } = item;

  if (url !== undefined) {
    return <ListItemLink item={item} disabled={disabled} />;
  }
  return <ListItemClick item={item} />;
};

const MultiLevel = ({ item, callback, openNav }: any) => {
  const { subMenu: children } = item;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!openNav) {
      setOpen(false);
    }
  }, [openNav]);

  const handleClick = () => {
    callback();
    setOpen((prev) => !prev);
  };

  const t = useTranslations('SideMenu');

  const isMenu = MAIN_MENU.some((menu: any) => menu.title === item.title);

  return (
    <React.Fragment>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          {item?.customIcon ? (
            <SvgIcon component={item.icon} inheritViewBox />
          ) : (
            <MuiSvgIcon
              component={item.icon}
              inheritViewBox
              sx={{ color: '#FFF' }}
            />
          )}
        </ListItemIcon>
        <ListItemText primary={`${t(item.title)}`} sx={{ color: '#FFF' }} />
        {open ? (
          <ExpandLessIcon sx={{ color: '#FFF' }} />
        ) : (
          <ExpandMoreIcon sx={{ color: '#FFF' }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            margin: 0,
            '& .MuiButtonBase-root': {
              paddingLeft: '18px',
            },
            ...(children.length > 3 && {
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: '200px',
              '&::-webkit-scrollbar-track': {
                borderRadius: '10px',
                backgroundColor: '#f5f5f5',
                WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3);',
              },
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#000',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                backgroundColor: 'primary.main',
              },
            }),
          }}
        >
          {children.map((child: any, key: any) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
