import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectDashboardState = (state: RootState) => state.dashboard;

export const selectShowPreferenceModal = createSelector(
  selectDashboardState,
  (state) => state.showPreferenceModal
);

export const selectDashboardData = createSelector(
  selectDashboardState,
  (state) => state.dashboardData
);

export const selectDashboardSummaryData = createSelector(
  selectDashboardState,
  (state) => state.dashboardSummary
);

export const selectedSummary = createSelector(
  selectDashboardState,
  (state) => state.selectedSummary
);

export const selectDefaultChartList = createSelector(
  selectDashboardState,
  (state) => state.defaultChartList
);

export const selectStarredSwims = createSelector(
  selectDashboardState,
  (state) => state.starredSwims
);

export const selectDefaultSummary = createSelector(
  selectDashboardState,
  (state) => state.defaultSummary
);

export const selectedChart = createSelector(
  selectDashboardState,
  (state) => state.selectedChart
);

export const selectProgressionData = createSelector(
  selectDashboardState,
  (state) => state.progressionData
);

export const selectChartNameList = createSelector(
  selectDashboardState,
  (state) => state.chartNameList
);

export const selectFavoriteCount = createSelector(
  selectDashboardState,
  (state) => state.swimFavoriteCount
);

export const selectDashboardLoading = createSelector(
  selectDashboardState,
  (state) => state.isLoading
);

//V3 Dashboard
export const selectDashboardTableData = createSelector(
  selectDashboardState,
  (state) => state.swimtListTable
);

export const selectDashboardPreferenceList = createSelector(
  selectDashboardState,
  (state) => state.dashboardPreferenceList
);

export const selectedDashboardPreference = createSelector(
  selectDashboardState,
  (state) => state.selectedDashboardPreference
);

export const selectPreferenceModalAction = createSelector(
  selectDashboardState,
  (state) => state.preferenceModalAction
);

export const selectDashboardProgressionChart = createSelector(
  selectDashboardState,
  (state) => state.progressionChartData
);

export const selectDashboardDateRange = createSelector(
  selectDashboardState,
  (state) => state.dashboardSelectedDateRange
);

export const selectDashboardSwimId = createSelector(
  selectDashboardState,
  (state) => state.selectedSwimId
);

export const selectIsOpenSideNav = createSelector(
  selectDashboardState,
  (state) => state.isOpenSideNav
);
