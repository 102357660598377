import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectSharedAccessState = (state: RootState) => state.sharedAccess;

export const selectSharedAccessModal = createSelector(
  selectSharedAccessState,
  (state) => state.modalIsOpen
);

export const selectSharedOwners = createSelector(
  selectSharedAccessState,
  (state) => state.swimmers
);

export const selectSharedUser = createSelector(
  selectSharedAccessState,
  (state) => state.sharedUsers
);
