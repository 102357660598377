import { Stack } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

import styles from '../team-dashboard/invite-team-members-modal.module.css';
import teamInviteStyles from '../team-dashboard/team-send-invites-form.module.css';

import { useDispatch, useSelector } from '../../hooks/redux-hooks';
import { selectSharedAccessModal } from '../../redux/shared-access/selectors';
import {
  sendShareAccessInvites,
  setSharedAcessModalState,
} from '../../redux/shared-access';

import ModalContainer from '../common/modal-container';
import yup from '../../utils/custom-yup';
import SubmitButton from '../common/submit-button';
import Input from '../common/input';
import Trash from '../../public/trash.svg';
import Plus from '../../public/plus.svg';
import { addNotification } from '../../redux/notifications';

interface InvitesData {
  invites: { email: string }[];
}

const schema = {
  swimmers: yup.object({
    invites: yup.array().of(
      yup
        .object({
          email: yup.string().email('Please provide a valid email.'),
        })
        .uniqueProperty('email', 'email must be unique.')
    ),
  }),
};

const ShareAccessInviteModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectSharedAccessModal);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors: formErrors },
  } = useForm<InvitesData>({
    resolver: yupResolver(schema.swimmers),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invites',
  });

  useEffect(() => {
    setValue('invites', [
      {
        email: '',
      },
      {
        email: '',
      },
      {
        email: '',
      },
    ]);
  }, [setValue]);

  const onSubmit = async (data: InvitesData) => {
    //  setInvitesDetails(data);
    // remove all empty emails
    let invites = data.invites.filter((invite) => invite.email.trim() !== '');
    if (invites.length === 0) {
      dispatch(
        addNotification({
          message: 'Please add at least one valid email.',
          type: 'error',
        })
      );
      return;
    }

    dispatch(sendShareAccessInvites(invites))
      .unwrap()
      .then((res) =>
        setValue('invites', [
          {
            email: '',
          },
          {
            email: '',
          },
          {
            email: '',
          },
        ])
      );
  };

  return (
    <>
      {isOpen && (
        <ModalContainer
          width="40rem"
          handleClose={() => dispatch(setSharedAcessModalState(false))}
        >
          <Stack
            justifyContent="center"
            gap={3}
            className={teamInviteStyles.container}
          >
            <div
              className={styles.modal__title}
              style={{ textAlign: 'center' }}
            >
              Share Access
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={teamInviteStyles.form}
            >
              {fields.map((field, index) => {
                const { invites } = formErrors;
                const emailKey: any = `invites.${index}.email`;
                const errorMessage: any =
                  (invites &&
                    invites[index] &&
                    invites[index].email &&
                    invites[index].email?.message) ??
                  '';
                return (
                  <div key={field.id}>
                    <div className={`${teamInviteStyles.inputs}`}>
                      <Input
                        key={`email-${field.id}`}
                        name={emailKey}
                        inputType="email"
                        placeholder="email address"
                        register={register(emailKey)}
                      />

                      <Trash onClick={() => remove(index)} />
                    </div>
                    <div className={teamInviteStyles.errorMessage}>
                      {errorMessage}
                    </div>
                  </div>
                );
              })}

              <div
                className={`${teamInviteStyles.create}`}
                onClick={() => append({ email: '' })}
              >
                <Plus />
                <span>Add another user</span>
              </div>
              <SubmitButton label="Send Invitations" />
            </form>
          </Stack>
        </ModalContainer>
      )}
    </>
  );
};
export default ShareAccessInviteModal;
