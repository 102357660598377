import * as yup from 'yup';

// custom validation for unique field in array
const customYup: any = yup;
customYup.addMethod(
  customYup.object,
  'uniqueProperty',
  function (this: any, propertyName: string, message: string) {
    return this.test('unique', message, function (this: any, value: any) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);
      if (
        subOptions.some(
          (option) => option[propertyName] === value[propertyName]
        )
      ) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  }
);

customYup.addMethod(
  customYup.object,
  'uniqueEmailAndType',
  function (this: any, message: string) {
    return this.test('unique', message, function (this: any, value: any) {
      if (!value || !value.email || !value.type) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);
      if (
        subOptions.some(
          (option) =>
            option['email'] === value['email'] &&
            option['type'] === value['type']
        )
      ) {
        throw this.createError({
          path: `${path}.email`,
          message,
        });
      }

      return true;
    });
  }
);
export default customYup;
