// For global selectors like loading
import { createSelector } from 'reselect';
import { Status } from '../enums/status';
import { Invite } from '../types/invite';
import { License } from '../types/license';
import { computeRemainingValidity } from '../utils/license';

import { selectChartIsLoading } from './chart';
import { selectCurrentTeamIsLoading } from './current-team';
import { selectGroupsIsLoading } from './groups';
import {
  selectSentInvites,
  selectInvitesIsLoading,
  selectInvites,
} from './invites';
import { selectJoinRequestsIsLoading } from './join-requests';
import { selectLicenses, selectLicensesIsLoading } from './licenses';
import { selectGlobalIsLoading } from './loading';
import { selectPendingInvitesIsLoading } from './pending-invites';
import { selectProductsIsLoading } from './products';
import { selectProfileIsLoading } from './profile';
import { selectSubscriptionsIsLoading } from './subscriptions';
import { selectSwimDataIsLoading } from './swim-data';
import { selectTeamsIsLoading } from './teams';
import { selectUser, selectUserIsLoading } from './user';

export const selectIsLoading = createSelector(
  // selectChartIsLoading,
  selectCurrentTeamIsLoading,
  selectGroupsIsLoading,
  selectInvitesIsLoading,
  selectJoinRequestsIsLoading,
  selectLicensesIsLoading,
  selectGlobalIsLoading,
  selectPendingInvitesIsLoading,
  selectProductsIsLoading,
  selectProfileIsLoading,
  selectSwimDataIsLoading,
  selectSubscriptionsIsLoading,
  selectTeamsIsLoading,
  selectUserIsLoading,
  (
    // chartIsLoading,
    currentTeamIsLoading,
    groupsIsLoading,
    invitesIsLoading,
    joinRequestsIsLoading,
    licensesIsLoading,
    globalIsLoading,
    pendingInvitesIsLoading,
    productsIsLoading,
    profileIsLoading,
    swimDataIsLoading,
    subscriptionsIsLoading,
    teamsIsLoading,
    userIsLoading
  ) =>
    // chartIsLoading ||
    currentTeamIsLoading ||
    groupsIsLoading ||
    invitesIsLoading ||
    joinRequestsIsLoading ||
    licensesIsLoading ||
    globalIsLoading ||
    pendingInvitesIsLoading ||
    productsIsLoading ||
    profileIsLoading ||
    swimDataIsLoading ||
    subscriptionsIsLoading ||
    teamsIsLoading ||
    userIsLoading
);

export const selectLicensesWithSentInvites = createSelector(
  selectLicenses,
  selectSentInvites,
  (licenses: License[], invites: Invite[]) => {
    return licenses.map((license) => {
      const invite = invites.find(
        ({ licence: { licenseId } }) => licenseId === license.licenseId
      );

      if (invite) {
        return { ...license, inviteDetails: invite };
      }

      return { ...license, inviteDetails: null };
    });
  }
);

export const selectLicensesWithAllInvites = createSelector(
  selectLicensesWithSentInvites,
  selectInvites,
  selectUser,
  (licenses, invites, user) => {
    const currentDate = new Date();
    // Setting the hours of the day to 0,0,0,0 which is 12 midnight
    // To compute the remaining days by days alone excluding time
    currentDate.setHours(0, 0, 0, 0);
    const transformInvites: License[] = invites.map((invite) => {
      return {
        ...computeRemainingValidity(invite.licence, currentDate),
        inviteDetails: invite,
        licenseOwnerDetails: invite.user,
      };
    });

    return [...licenses, ...transformInvites];
  }
);
