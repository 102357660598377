import { useState, useEffect } from 'react';

import styles from './errors-list.module.css';
import textStyles from '../../styles/base/typography.module.css';

import { useErrorsContext } from '../../context/errors';

interface Props {
  errorMessage?: string;
}

// As to avoid confusion I have name this reusable component as ErrorsList instead of just Errors
const ErrorsList = ({ errorMessage }: Props) => {
  const { errors, showErrors, resetErrors } = useErrorsContext();
  const [currentErrors, setCurrentErrors] = useState<string[]>([]);

  // Global context errors contents are copied to currentErrors to create a local copy of the errors of the
  // page and also in order for us to set it to []
  useEffect(() => {
    if (showErrors && errors.length > 0) {
      setCurrentErrors(errors);
      resetErrors();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, showErrors]);

  return (
    <>
      {showErrors && (
        <ul className={styles.errors}>
          {currentErrors.length > 0 &&
            currentErrors.map((error, index) => (
              <li key={index} className={textStyles.error}>
                {error}
              </li>
            ))}

          {errorMessage && <li className={textStyles.error}>{errorMessage}</li>}
        </ul>
      )}
    </>
  );
};

export default ErrorsList;
