import Home from '../../../public/home.svg';
import BarChart from '../../../public/bar-chart.svg';
import Users from '../../../public/users.svg';
import Video from '../../../public/video.svg';
import Buoy from '../../../public/buoy.svg';
import Gear from '../../../public/gear-white.svg';
import ShareIcon from '@mui/icons-material/Share';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import { YOUR_SWIMS_ROUTE } from '../../../constants/menu';

import { signOutUser } from '../../../redux/user';
import { useDispatch } from '../../../hooks/redux-hooks';
import { store } from '../../../redux/store';
import router from 'next/router';
import { LABELS } from '../../../constants/labels';
import { setSharedAcessModalState } from '../../../redux/shared-access';

const signOut = async () => {
  await store.dispatch(signOutUser());
  router.push('/sign-in');
};

const handleShare = async () => {
  await store.dispatch(setSharedAcessModalState(true));
};

const MAIN_MENU: any = [
  {
    title: 'dashboard',
    url: `${YOUR_SWIMS_ROUTE}`,
    icon: Home,
    customIcon: true,
  },
  {
    title: `membership-manager`,
    url: '/licence-manager',
    icon: ManageAccountsIcon,
    customIcon: false,
  },
  {
    title: 'teams',
    icon: GroupsIcon,
    customIcon: false,
  },
  {
    title: 'shared-swimmer',
    url: '/swimbetter/shared-access/',
    icon: Diversity2Icon,
    customIcon: false,
  },
  // {
  //   title: 'Video',
  //   url: '',
  //   icon: Video,
  //   role: 'user',
  // },
];

const USER_MENU = [
  // {
  //   title: 'Support',
  //   icon: Buoy,
  //   customIcon: true,
  //   subMenu: [],
  // },
  {
    title: 'settings',
    icon: Gear,
    customIcon: true,
    subMenu: [
      {
        title: 'share',
        icon: ShareIcon,
        customIcon: false,
        callback: () => handleShare(),
      },
      {
        title: 'logout',
        icon: LogoutIcon,
        customIcon: false,
        callback: () => signOut(),
      },
    ],
  },
];

export { MAIN_MENU, USER_MENU };
