import styles from './submit-button.module.css';
import buttonStyles from '../../styles/components/buttons.module.css';

interface Props {
  label: string;
  disabled?: boolean;
  [x: string]: any;
}

const SubmitButton = ({ label, disabled = false, ...props }: Props) => {
  return (
    <button
      type="submit"
      className={`${buttonStyles.button} ${styles.submit} ${
        disabled ? styles.disabled : ''
      }`}
      disabled={disabled}
      {...props}
    >
      {label}
    </button>
  );
};

export default SubmitButton;
