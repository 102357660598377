import { ReactElement, ReactNode } from 'react';
import modalStyles from '../../../styles/components/modal.module.css';
import textStyles from '../../../styles/base/typography.module.css';
import styles from './index.module.css';
import { useTranslations } from 'next-intl';

interface Props {
  width?: string;
  title?: string | ReactNode;
  btnLabel?: string | ReactElement;
  titlePosition?: 'left' | 'right' | 'center';
  disabled?: boolean;
  hasAction?: boolean;
  children?: ReactNode;
  handleClose?: Function;
  handleSubmit?: Function;
  customStyle?: string;
  contentMargin?: boolean;
}

const ModalContainer = ({
  width = '27rem',
  title,
  titlePosition = 'center',
  btnLabel,
  children,
  disabled,
  hasAction,
  handleClose,
  handleSubmit,
  customStyle,
  contentMargin = true,
}: Props) => {
  const tc = useTranslations('Common');

  const handleCancel = () => {
    handleClose();
  };
  return (
    <>
      <div
        className={`${modalStyles.background}`}
        onClick={() => handleClose()}
      />
      <div className={`${modalStyles.centered} `}>
        <div
          className={`${styles.modal} ${customStyle}`}
          style={{ width: `${width}`, gap: '.5rem' }}
        >
          {title && (
            <div
              className={`${styles.modal__header} ${textStyles['extra-large']} ${textStyles['mid-gray']} ${styles[titlePosition]}`}
            >
              {title}
            </div>
          )}
          <div
            style={{
              padding: `${contentMargin ? '0 2rem' : '0'}`,
              width: '100%',
            }}
          >
            {children}
          </div>
          {hasAction && (
            <div className={styles['action__wrapper']}>
              <button
                className={`${styles['action-btn']} ${styles['cancel-btn']}`}
                onClick={handleCancel}
              >
                {tc('cancel')}
              </button>
              <button
                className={`${styles['action-btn']} ${styles['submit-btn']} ${
                  disabled && styles.disabled
                }`}
                disabled={disabled}
                onClick={() => handleSubmit()}
              >
                {btnLabel ? btnLabel : tc('submit')}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalContainer;
