import StokePathAndForce from '../components/v3-components/swimmer/stroke-path-and-force';
import { Menu } from '../types/menu';

export const YOUR_SWIMS_ROUTE = '/swimbetter/swimmers/your-swims';
export const ADMINS_COACHES_DASHBOARD_ROUTE =
  '/swimbetter/admins-and-coaches/team-dashboard';

const YOUR_SWIMS_MENU: Menu = {
  // This is the old tempo

  STROKE_RATE_AND_FORCE: {
    name: 'Stroke Rate & Force',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-rate-and-force`,
    url: 'stroke-rate-and-force',
    videoSync: false,
  },
  FORCEFIELD: {
    name: 'Force Field',
    endpoint: `${YOUR_SWIMS_ROUTE}/forcefield`,
    url: 'forcefield',
    videoSync: false,
  },
  STROKE_PATH_AND_HAND_VELOCITY: {
    name: 'Stroke Path & Hand Velocity',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-path-and-hand-velocity`,
    url: 'stroke-path-and-hand-velocity',
    videoSync: false,
  },
  CONSISTENCY: {
    name: 'Consistency',
    endpoint: `${YOUR_SWIMS_ROUTE}/consistency`,
    url: 'consistency',
    videoSync: false,
  },
  STROKE_PHASES: {
    name: 'Stroke Phases',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-phases`,
    url: 'stroke-phases',
    videoSync: false,
  },
  FORCE_VS_TIME: {
    name: 'Force vs Time',
    endpoint: `${YOUR_SWIMS_ROUTE}/force-vs-time`,
    url: 'force-vs-time',
    videoSync: true,
  },
  STROKE_PATH_AND_FORCE: {
    name: 'Stroke Path & Force',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-path-and-force`,
    url: 'stroke-path-and-force',
    videoSync: false,
  },
};

export { YOUR_SWIMS_MENU };
