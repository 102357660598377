import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectInvitesState = (state: RootState) => state.invites;

export const selectInvites = createSelector(
  selectInvitesState,
  (state) => state.invites
);

export const selectSentInvites = createSelector(
  selectInvitesState,
  (state) => state.sentInvites
);

export const selectShowDuplicateInviteModal = createSelector(
  selectInvitesState,
  (state) => state.showDuplicateInviteModal
);

export const selectInvitesErrors = createSelector(
  selectInvitesState,
  (state) => state.errors
);

export const selectInvitesIsLoading = createSelector(
  selectInvitesState,
  (state) => state.isLoading
);
