import Box from '@mui/material/Box';
import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';

import BarLoader from 'react-spinners/BarLoader';
import { selectIsLoading } from '../../redux/selectors';
import { useErrorsContext } from '../../context/errors';
import Sidebar from '../v3-components/layout/sidebar';
import CoachHeader from '../v3-components/coach/coach-header';
import MaintenanceBadge from '../maintenance/maintenance-badge';
import ModalContainer from '../common/modal-container';
import ShareAccessInviteModal from '../share-access-invite/share-access-invite-modal';

interface Props {
  children: ReactNode;
  coach?: Boolean;
  hideSidebar?: Boolean;
}

const Layout = ({ children, coach, hideSidebar }: Props) => {
  const isLoading = useSelector(selectIsLoading);

  const { showErrors, setShowErrors } = useErrorsContext();

  // This is needed for those redirect behavior when errors are catch and showErrors on that page was hidden
  // so that errors will be displayed upon completion of redirect behavior
  useEffect(() => {
    if (!showErrors) {
      setShowErrors(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {isLoading && <BarLoader loading={true} color="#2299DD" width="100%" />}

      <Box sx={{ display: 'flex' }}>
        {!hideSidebar && <Sidebar />}

        <Box
          component="main"
          sx={{
            backgroundColor: '#FFFFFF',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {/* <MaintenanceBadge /> */}
          {coach && <CoachHeader />}

          {children}
        </Box>
        <ShareAccessInviteModal />
      </Box>
    </Box>
  );
};

export default Layout;
