import {
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import MuiToolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import React from 'react';
import { coachHeaderStyle } from './coach-header.style';
import { useDispatch, useSelector } from '../../../hooks/redux-hooks';
import { selectGroupsList } from '../../../redux/groups';
import {
  resetCurrentSwimData,
  selectCurrentSwimUser,
  setCurrentSwimUser,
} from '../../../redux/swim-data';
import { updateSelectedSwimId } from '../../../redux/dashboard';
import { setChartTab } from '../../../redux/chart';
import { YOUR_SWIMS_MENU } from '../../../constants/menu';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useRouter } from 'next/router';
const Toolbar = styled(MuiToolbar)({
  width: 'auto',
  height: 82,
  background: '#000',
  '& path': {
    fill: 'transparent',
  },
});

const CoachHeader = () => {
  const dispatch = useDispatch();
  const currentSwimmerUser = useSelector(selectCurrentSwimUser);
  const groups = useSelector(selectGroupsList);
  const router = useRouter();

  const handleChange = async (event: SelectChangeEvent) => {
    await dispatch(setCurrentSwimUser(event.target.value as string));
    dispatch(updateSelectedSwimId(''));
    dispatch(resetCurrentSwimData());
    dispatch(setChartTab(YOUR_SWIMS_MENU.STROKE_RATE_AND_FORCE.url));
  };

  const renderMenu = () => {
    const arrayMenu: any = [];
    groups.map((group) => {
      arrayMenu.push(
        <ListSubheader key={`group-option-${group.id}`}>
          {group.name}
        </ListSubheader>
      );
      group.members.map((member) => {
        if (member.type === 3 && member.memberType === 0) {
          arrayMenu.push(
            <MenuItem
              key={`group-${group.id}-option-member-${member.id}`}
              value={member.id}
            >{`${member.firstName} ${member.lastName}`}</MenuItem>
          );
        }
      });
    });
    return arrayMenu;
  };

  return (
    <Toolbar>
      {selectCurrentSwimUser && (
        <Select
          sx={coachHeaderStyle.input}
          labelId="select-swim"
          defaultValue={currentSwimmerUser}
          value={currentSwimmerUser}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownRoundedIcon}
        >
          {renderMenu()}
        </Select>
      )}
    </Toolbar>
  );
};

export default CoachHeader;
