import { UseFormRegisterReturn } from 'react-hook-form';

import ErrorsList from './errors-list';

import styles from './input.module.css';

interface Props {
  errorMessage?: string;
  inputType: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  name?: string;
  onChange?: any;
  defaultValue?: any;
  data?: any;
}

const Input = ({
  data,
  inputType,
  register,
  errorMessage = '',
  placeholder = '',
  disabled = false,
  maxLength,
  minLength,
  name = '',
  defaultValue = '',
  onChange = () => {},
}: Props) => {
  return (
    <>
      <input
        {...data}
        name={name}
        type={inputType}
        className={`${styles.input} ${disabled && styles.disabled}`}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        onChange={onChange}
        defaultValue={defaultValue}
        {...register}
      />
      {errorMessage !== '' && <ErrorsList errorMessage={errorMessage} />}
    </>
  );
};

export default Input;
